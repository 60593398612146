
import rest from '../../common/utils/rest';
import { ApiURL } from '../../common/config/websiteConfig';

let TOKEN = window.localStorage.getItem('token') || null;
console.log(window.localStorage.getItem('token'))
let header  = { headers: {'everybim-token': TOKEN}};

export const USER_INFO = 'USER_INFO';
export const USER_AVATAR = 'USER_AVATAR';

export function _concatUrl(url) {
    return `${ApiURL}/v1/account-service/${url}`;
}

function _hookError(error, message) {
    console.log(message + '出现错误 => ', error);
    throw error;
}


//获取上传权限 token
export function getFileToken(fileInfo, type, config) {
    let url = `${ApiURL}/v3/oss/access/${type}`;
    return rest.post(url,fileInfo,config)
        .then(result => result)
        .catch(err => _hookError(err, "获取文件上传token"))
}


//存储模型数据
export function saveUserData(key) {
    let url = `${ApiURL}v3/info/key/${key}`
    return rest.get(url)
        .then(result => result)
        .catch(err => _hookError(err, "存储模型数据"))
}

//获取存储的数据
export function getUserData(key) {
    let url = `${ApiURL}v3/info/key/${key}`
    return rest.get(url)
        .then(result => result)
        .catch(err => _hookError(err, "获取存储的数据"))
}

//更新用户信息
export function updateUserInfo(data){
    return dispatch => {
        return rest.post(`${ApiURL}/v1/user/update`,data)
        .then(result => result)
        .catch(err => _hookError(err, "更新用户信息"))
    } 
}

export function getUserAvatar() {
    return dispatch => {
        return rest.get(`${ApiURL}/v1/user/head/image`)
            .then(result => {
                dispatch({
                    type: USER_AVATAR,
                    payload: result && result.data
                });
                return result;
            })
            .catch(err => _hookError(err, "获取用户头像"))
    }   
}

export function resetAppsecret(data,config){
    let url  = `${ApiURL}/v3/user/reset/secret`;
    return rest.post(url,data,config)
       .then(result => result)
       .catch(err => _hookError(err, "重置Appsecret"))
}

export function getUserInfo(config) {
    return dispatch => {
        return rest.get(`${ApiURL}/v3/user/info`,config)
            .then(result => {
                dispatch({
                    type: USER_INFO,
                    payload: result && result.data
                });
                return result;
            })
            .catch(err => _hookError(err, "获取应用信息"))
    }   
}





