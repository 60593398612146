import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Layout, BackTop} from 'antd';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import {routers} from './routers';
import store from '../store/index';
// import Footer from '../common/component/Footer';
// import Header from '../common/component/Header';
import ScrollToTop from "./withRouter";

const COMPONENT_ID = "COMPONENT_ID";

let ROUTES = routers;

export default class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <ScrollToTop>
                {
                  ROUTES.map(({ path,title, exact, isNav, isConsole, component: Component }) => (
                    <Route
                      key={path}
                      path={path}
                      exact={true}
                      render={(props) => {
                        document.title = `${title === '首页'? 'EveryBIM 图形引擎':title}`;
                        return (
                          <Layout>
                            <div id={COMPONENT_ID}>
                                <Component  {...props} />
                            </div>
                          </Layout>
                        )
                      }}
                    />
                  ))
                }
              </ScrollToTop>
            </Switch>
          </Layout>
        </BrowserRouter>
      </Provider>
    )

  }
}

