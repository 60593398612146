/**
 * 用来保存全局常量
 * 模型服务器配置
 */
let ModelConfig;
    
//模型列表 模型服务器配置
ModelConfig = {
  //中间件官网测试 3.0
  serverIp: '',
};

//打包 server 置空
let server = '';
// let server = 'http://192.168.1.130:32200';
//let server ='http://192.168.1.111:3101';
let APIURL = `${server}/api`;

// dev环境
let baseURL = '';  

//场景示例模型
let EXAMPLE_MODEL = { modelId: '610a2c06be3f082135f8aea1', version: 1 }
let EXAMPLE_DRAWING = { modelId: '60a4d10fa0b039669aa2df0c', version: 1 }

module.exports = {
  baseURL,
  ApiURL: APIURL,
  modelConfig: ModelConfig,
  EXAMPLE_MODEL: EXAMPLE_MODEL,
  EXAMPLE_DRAWING: EXAMPLE_DRAWING
}