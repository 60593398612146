
import { AsyncComponent } from '../common/utils/AsyncComponent'

export const routers = [
    {
        path: '/',
        exact: true,
        isConsole: true,
        title: '图形引擎管理',
        component: AsyncComponent(() => import("../developer/console")),
    },
    {
        path: '/userInfo',
        exact: true,
        title: '个人中心',
        component: AsyncComponent(() => import("../developer/userInfo")),
    },
    {
        path: '/verifyPhone',
        exact: true,
        title: '修改手机号',
        component: AsyncComponent(() => import("../developer/verifyPhone")),
        
    },
    {
        path: '/login',
        exact: true,
        title: '登录',
        component: AsyncComponent(() => import("../pages/user/Login/login")),
    },
    {
        path: '/findPassword',
        exact: true,
        title: '找回密码',
        component: AsyncComponent(() => import("../pages/user/PasswordFind/passwordFind")),  
    },
    {
        path: '/register',
        exact: true,
        title: '找回密码',
        component: AsyncComponent(() => import("../pages/user/Register/register")),  
    },
    {
        path: '/model',
        exact: true,
        title: '模型预览',
        component: AsyncComponent(() => import("../pages/bimModel/bimModel")),
    },
    {
        path: '/drawing',
        exact: true,
        title: '图纸预览',
        component: AsyncComponent(() => import("../pages/bimDrawing/bimDrawing")),
    },
   
    {  
        path: '/modelEffect',
        exact: true,
        title: '场景-模型效果',
        component: AsyncComponent(() => import("../pages/scene/module/modelEffect")),
    },
    {  
        path: '/linkage',
        exact: true,
        title: '场景-图模联动',
        component: AsyncComponent(() => import("../pages/scene/module/linkage")),
    },
    {  
        path: '/versionCompare',
        exact: true,
        title: '场景-版本对比',
        component: AsyncComponent(() => import("../pages/scene/module/versionCompare")),
    },
    {  
        path: '/BIMPlatform',
        exact: true,
        title: '场景-BIM+协同平台',
        component: AsyncComponent(() => import("../pages/scene/module/BIMPlatform")),
    },
    {  
        path: '/digitalMap',
        exact: true,
        title: '场景-数字化审图',
        component: AsyncComponent(() => import("../pages/scene/module/digitalMap")),
    },
];